<template>
  <enterpriseForm
    :isEdit="$route.params.isEdit"
    :detailData="detailData"
    :id="this.$route.params.id"
  ></enterpriseForm>
</template>
<script>
import enterpriseForm from "./enterpriseForm.vue";
import { getEnterpriseDetail } from "@/api/safeDuty.js";
export default {
  components: { enterpriseForm },
  data() {
    return {
      detailData: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    } else {
      this.detailData = null;
    }
  },
  methods: {
    async getDetail(id = "") {
      let res = await getEnterpriseDetail({
        insInsuredId: this.$route.params.id,
      });
      this.detailData = res.data;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
