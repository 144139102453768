<template>
  <div style="height: 100%">
    <el-form style="height: 90%; overflow: auto">
      <AssociationScroll>
        <AssociationScrollView title="基本信息" name="0">
          <div class="base-info">
            <div class="base-info-name">{{ dataForm.firmName }}</div>
            <div class="base-info-address">
              {{ dataForm.areaProvince }}.{{ dataForm.areaCity }}
            </div>
          </div>
          <TextInputContainer>
            <el-form-item
              v-for="(config, index) in baseItemsConfig"
              :key="index"
            >
              <TextInput
                isText
                :config="config"
                v-model="dataForm[config.modelKey]"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
        </AssociationScrollView>
        <AssociationScrollView title="补充信息" name="1">
          <TextInputContainer>
            <el-form-item>
              <TextInput
                @returnVal="getKeyToValue"
                :isText="!isEdit"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  label: '企业位置情况：',
                  type: 'select',
                  modelTextKey: 'positionConditionValue',
                  option: this.dictList.firmPositionCondition,
                }"
                v-model="dataForm.positionConditionKey"
              ></TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                @returnVal="getKeyToValue"
                :isText="!isEdit"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  label: '企业性质：',
                  type: 'select',
                  modelTextKey: 'natureValue',
                  option: this.dictList.firmNature,
                }"
                v-model="dataForm.natureKey"
              ></TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '企业人员规模(含劳务人员)：',
                  type: 'inputNumber',
                }"
                v-model="dataForm.naturePeopleNumber"
              ></TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                :isText="!isEdit"
                :config="{
                  label: '企业实际员工人数：',
                  type: 'inputNumber',
                }"
                v-model="dataForm.actualPeopleNumber"
              ></TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                @returnVal="getKeyToValue"
                :isText="!isEdit"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  label: '重点监管类型：',
                  type: 'select',
                  modelTextKey: 'superviseTypeValue',
                  option: this.dictList.superviseType,
                }"
                v-model="dataForm.superviseTypeKey"
              ></TextInput>
            </el-form-item>
            <el-form-item>
              <TextInput
                @returnVal="getKeyToValue"
                :isText="!isEdit"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  label: '企业行业类型：',
                  type: 'select',
                  modelTextKey: 'industryTypeValue',
                  option: this.dictList.firmIndustryType,
                }"
                v-model="dataForm.industryTypeKey"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
          <el-form-item
            ><TextInput
              :isText="!isEdit"
              :config="{
                label: '企业其他情况描述：',
                type: 'textarea',
                width: '100%',
              }"
              v-model="dataForm.otherDescription"
            ></TextInput
          ></el-form-item>
          <el-form-item
            ><TextInput
              :isText="!isEdit"
              :config="{
                label: '企业主要业务：',
                type: 'textarea',
                width: '100%',
              }"
              v-model="dataForm.mainBusiness"
            ></TextInput
          ></el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="保单列表" name="2">
          <div class="total">
            <div class="total-title">总保费(元)：</div>
            <div class="total-value">
              {{ addThousandSeparator(totalAmount.totalPremium) }}
            </div>
          </div>
          <SimpleTable
            :queryFun="policyqueryFun"
            :extraParams="params"
            :tableProps="policyTableProps"
          >
            <template slot="policyNumber" slot-scope="{ data }">
              <el-button
                @click="toDetail(false, data.row, 'safeDutyManagementDetail')"
                type="text"
                >{{ data.row.policyNumber }}</el-button
              >
            </template>
            <template slot="policyStatusName" slot-scope="{ data }">
              <div :style="sdPolicyStatus[data.row.policyStatusName].style">
                {{ data.row.policyStatusName }}
              </div>
            </template>
          </SimpleTable>
        </AssociationScrollView>
        <AssociationScrollView title="服务记录" name="3">
          <div class="total">
            <div class="total-title">服务总金额(元)：</div>
            <div class="total-value">
              {{ addThousandSeparator(totalAmount.serviceTotalAmount) }}
            </div>
          </div>
          <SimpleTable
            :queryFun="servicequeryFun"
            :extraParams="params"
            :tableProps="serviceTableProps"
          >
            <template slot="serviceNo" slot-scope="{ data }">
              <el-button
                @click="
                  toDetail(false, data.row, 'safeDutyServiceManagementDetail')
                "
                type="text"
                >{{ data.row.serviceNo }}</el-button
              >
            </template>
            <template slot="reportStatus" slot-scope="{ data }">
              <div :style="sdReportStatus[data.row.reportStatus].style">
                {{ sdReportStatus[data.row.reportStatus].name }}
              </div>
            </template>
          </SimpleTable>
        </AssociationScrollView>
        <AssociationScrollView title="理赔记录" name="4">
          <div class="total">
            <div class="total-title">理赔总金额(元)：</div>
            <div class="total-value">
              {{ addThousandSeparator(totalAmount.claimsTotalAmount) }}
            </div>
          </div>
          <SimpleTable
            :queryFun="claimqueryFun"
            :extraParams="params"
            :tableProps="claimTableProps"
            ><template slot="caseNo" slot-scope="{ data }">
              <el-button
                @click="toDetail(false, data.row, 'safeDutyClaimDetail')"
                type="text"
                >{{ data.row.caseNo }}</el-button
              >
            </template></SimpleTable
          >
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <div class="sd-bottom-btn">
      <el-button @click="back">返回</el-button>
      <el-button v-if="isEdit" type="primary" @click="submitForm"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
// import TextInput from "../components/TextInput";
// import TextInputContainer from "../components/TextInputContainer";
import TextInput from "@/components/SimpleTable/TextInput.vue";
import TextInputContainer from "@/components/SimpleTable/TextInputContainer.vue";
import SimpleTable from "../components/SimpleTable";
import {
  getEnterpriseDetail,
  getServiceRecordPage,
  getClaimRecordPage,
  editEnterprise,
} from "@/api/safeDuty.js";
import { dictionaryBatch } from "@/api/policy";
import { addThousandSeparator } from "@/utils/dealNumber";
import { sdPolicyStatus, sdReportStatus } from "@/const/policyConst.js";
import { data } from "jquery";
import { init } from "echarts";
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    TextInput,
    TextInputContainer,
    SimpleTable,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    detailData(n) {
      if (n) {
        this.dataForm = { ...this.dataForm, ...n };
      }
    },
  },
  created() {
    this.params.insInsuredId = this.id;
    this.init();
  },
  data() {
    return {
      dictList: {
        firmPositionCondition: [],
        firmNature: [],
        superviseType: [],
        firmIndustryType: [],
      },
      sdPolicyStatus: sdPolicyStatus,
      sdReportStatus: sdReportStatus,
      params: { insInsuredId: "", policyId: "" },
      totalAmount: {
        serviceTotalAmount: 0,
        claimsTotalAmount: 0,
        totalPremium: 0,
      },
      addThousandSeparator: addThousandSeparator,
      dataForm: {
        actualPeopleNumber: "",
        areaCity: "",
        areaProvince: "",
        contactNumber: "",
        firmAddress: "",
        firmName: "",
        id: "",
        idNumber: "",
        idType: "",
        idTypeKey: "",
        idTypeValue: "",
        industryName: "",
        industryTypeKey: "",
        industryTypeValue: "",
        mainBusiness: "",
        natureKey: "",
        natureName: "",
        naturePeopleNumber: "",
        natureValue: "",
        otherDescription: "",
        positionConditionKey: "",
        positionConditionName: "",
        positionConditionValue: "",
        superviseTypeKey: "",
        superviseTypeName: "",
        superviseTypeValue: "",
      },
      baseItemsConfig: [
        {
          label: "证件类型：",
          type: "input",
          modelKey: "idType",
        },
        {
          label: "证件号码：",
          type: "input",
          modelKey: "idNumber",
        },
        {
          label: "联系电话：",
          type: "input",
          modelKey: "contactNumber",
        },
        {
          label: "被保险人地址：",
          type: "input",
          modelKey: "firmAddress",
        },
      ],
      policyTableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        columnProps: [
          {
            prop: "",
            label: "ID",
            width: "55",
            type: "index",
            slot: false,
            isShow: true,
          },
          {
            prop: "policyNumber",
            label: "保单号",
            width: "",
            slot: true,
            isShow: true,
          },

          {
            prop: "insuranceCompanyName",
            label: "保险公司",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "planName",
            label: "方案名称",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "salesmanName",
            label: "业务员",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceStartDate",
            label: "起保日期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceEndDate",
            label: "保险到期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "totalPremium",
            label: "总保费(元)",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "policyStatusName",
            label: "保单状态",
            width: "",
            slot: true,
            isShow: true,
          },
        ],
      },
      claimTableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        columnProps: [
          {
            prop: "",
            label: "ID",
            width: "55",
            type: "index",
            slot: false,
            isShow: true,
          },
          {
            prop: "caseNo",
            label: "案件编号",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },

          {
            prop: "realCaseNo",
            label: "报案号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "dangerType",
            label: "出险类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "accidentType",
            label: "事故类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "appraisalAgency",
            label: "公估机构",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "caseOfficer",
            label: "案件跟进人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "dangerDate",
            label: "出险时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "compensationTotalAmount",
            label: "赔付总金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "报案时间",
            width: "",
            type: "",
            isShow: true,
          },
        ],
      },
      serviceTableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        totalKey: "total",
        columnProps: [
          {
            prop: "",
            label: "ID",
            width: "55",
            type: "index",
            slot: false,
            isShow: true,
          },
          {
            prop: "serviceNo",
            label: "服务编号",
            width: "",
            slot: true,
            isShow: true,
          },

          {
            prop: "serviceTypesOf",
            label: "服务类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceTypeName",
            label: "服务种类",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "designateExpert",
            label: "服务专家",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "expertServiceAmount",
            label: "专家金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceAmount",
            label: "服务金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "signInTime",
            label: "服务时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "reportStatus",
            label: "报告状态",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          // {
          //   prop: "serviceProjectName",
          //   label: "服务项目",
          //   width: "",
          //   type: "",
          //   isShow: true,
          // },
          // {
          //   prop: "serviceManagerName",
          //   label: "服务负责人",
          //   width: "",
          //   type: "",
          //   isShow: true,
          // },
          // {
          //   prop: "serviceDate",
          //   label: "服务时间",
          //   width: "",
          //   type: "",
          //   isShow: true,
          // },
          // {
          //   prop: "serviceCost",
          //   label: "服务费用",
          //   width: "",
          //   type: "",
          //   isShow: true,
          // },
          // {
          //   prop: "remark",
          //   label: "备注",
          //   width: "",
          //   type: "",
          //   isShow: true,
          // },
        ],
      },
    };
  },
  methods: {
    init() {
      this.getDictionary();
    },
    getDictionary() {
      let dicts = [];
      Object.keys(this.dictList).forEach((key) => {
        dicts.push(key);
      });
      dictionaryBatch({ codes: dicts.join(",") }).then((res) => {
        if (res && res.data) {
          dicts.forEach((key) => {
            this.dictList[key] = res.data[key] || [];
          });
        }
      });
    },
    getKeyToValue(res, obj) {
      if (obj) {
        obj[res.type] = res.data.dictValue;
      } else {
        this.dataForm[res.type] = res.data.dictValue;
      }
    },
    async policyqueryFun(queryParams) {
      let res = await getEnterpriseDetail(queryParams);
      this.totalAmount.totalPremium = res.data.totalPremium || 0;
      return {
        localData: res.data.insurancePolicyList.records || [],
        total: res.data.insurancePolicyList.total,
      };
    },
    async claimqueryFun(queryParams) {
      let res = await getClaimRecordPage(queryParams);
      this.totalAmount.claimsTotalAmount = res.data.claimsTotalAmount || 0;
      return {
        localData: res.data.insAzxClaimsRespList.records || [],
        total: res.data.insAzxClaimsRespList.total,
      };
    },
    async servicequeryFun(queryParams) {
      let res = await getServiceRecordPage(queryParams);
      this.totalAmount.serviceTotalAmount = res.data.serviceTotalAmount || 0;
      return {
        localData: res.data.insServiceManagementRespList.records || [],
        total: res.data.insServiceManagementRespList.total,
      };
    },
    toDetail(edit = false, item = {}, type = "") {
      this.$router.push({
        name: type,
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
    submitForm() {
      editEnterprise(this.dataForm).then((res) => {
        this.$message({
          type: "success",
          message: "企业编辑成功!",
        });
        this.back();
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .action-container {
  padding: 0 !important;
}
.table-add-btn {
  background: #ffffff;
  border: 1px solid #dee3e6;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
}
.base-info {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  &-name {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
  }
  &-address {
    height: 27px;
    background: rgba(44, 204, 20, 0.1);
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    padding: 4px 12px;
    color: #2ccc14;
    margin-left: 16px;
  }
}
.total {
  display: flex;
  align-items: center;
  margin: 16px 0;
  &-ttitle {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  &-value {
    font-weight: bold;
    font-size: 18px;
    color: #3470ff;
  }
}
.sd-bottom-btn {
  width: 100%;
  height: 80px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  z-index: 99;
}
</style>
